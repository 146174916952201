import React from "react"
import { useTranslation, Trans } from "react-i18next"


import Layout from "../components/Layout"
import SEO from "../components/seo"
import "../components/i18n"

import screenShot from "../images/scr-ios.png"
import clip from "../images/play-cogito-solitaire-1.mp4"

const Help: React.FC = () => {
  const { t, i18n } = useTranslation()
  return (
    <Layout title={t("title")}>
      <SEO title={t("help.title")} />
      <h3>
        {t("help.title")}
      </h3>
      <img
        src={screenShot}
        alt={t("help.imgAlt")}
        style={{
          clear: "both",
          display: "block",
          maxWidth: "100%",
          padding: "10px",
          paddingLeft: "0",
        }}
      />
      <h4>
        {t("help.h1.title")}
      </h4>
      <p>
        {t("help.h1.p1")}
      </p>
      <p>
        {t("help.h1.p2")}
      </p>
      <h4>
        {t("help.h2.title")}
      </h4>
      <p>
        {t("help.h2.p1")}
      </p>
      <video src={clip} width="100%" controls></video>
      <p>
        {t("help.h2.p2")}
      </p>
      <h4>
        {t("help.h3.title")}
      </h4>
      <p>
        {t("help.h3.p1")}
      </p>
      <p>
        {t("help.h3.p2")}
      </p>
      <p>
        {t("help.h3.p3")}
      </p>
    </Layout>
  )
}

export default Help
